<script setup lang="ts">
import { useCompanyStore } from "@/store/company";

interface Props {
  reduced?: boolean;
}

defineProps<Props>();
const { company } = storeToRefs(useCompanyStore());

const purchaseCredits = () => {
  navigateTo(ROUTES.dashboardPayments);
};
const credits = computed(() => company.value?.credits ?? 0);
</script>

<template>
  <div>
    <div v-if="!reduced" class="p-4 bg-primary/20 border border-primary rounded-md items-center flex justify-between">
      <p class="text-primary font-medium">You have {{ credits }} Job Credits</p>
      <PrimaryButton @click="purchaseCredits" secondary>Buy Credits</PrimaryButton>
    </div>
    <OutlineButton v-else @click="purchaseCredits"
      ><Icon v-if="!credits" name="ri:error-warning-fill" class="h-5 w-5 mr-2 text-danger" aria-hidden="true" />{{ credits }} Job
      Credits</OutlineButton
    >
  </div>
</template>
